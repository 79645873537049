
/* key mov photo ico */
.ic {
  display:  none;
}
.ic::before {
  content: '';
  display: flex;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: min(5vw, 18px);
  height: min(5vw, 20px);
  margin: 0 0.25rem 0 0;
}
.caption > .ic {
  margin: 0;
  vertical-align: middle;
}
.caption > .ic::before {
  width: min(5vw, 24px);
  height: min(5vw, 28px);
}

[data-pay="0"] .pay {
  display: none;
}
[data-pay="1"] .pay,
[data-pay="2"] .pay,
[data-pay="3"] .pay {
  display: inline-block;
  position: relative;
  bottom: 0.24rem;
}
[data-pay="1"] .pay::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%23fec00b;}</style></defs><path class="cls-1" d="M26.03,30.08H9.97c-1.59,0-2.89-1.3-2.89-2.89v-9.87c0-1.59,1.3-2.89,2.89-2.89H26.03c1.59,0,2.89,1.3,2.89,2.89v9.87c0,1.59-1.3,2.89-2.89,2.89ZM9.97,16.43c-.49,0-.89,.4-.89,.89v9.87c0,.49,.4,.89,.89,.89H26.03c.49,0,.89-.4,.89-.89v-9.87c0-.49-.4-.89-.89-.89H9.97Z"/><path class="cls-1" d="M25.87,15.13h-2.5v-1.33c0-2.97-2.42-5.39-5.39-5.39s-5.39,2.42-5.39,5.39v1.27h-2.5v-1.27c0-4.35,3.54-7.89,7.89-7.89s7.89,3.54,7.89,7.89v1.33Z"/><path class="cls-1" d="M18.99,23.05v2.48h-1.85v-2.48c-.75-.35-1.28-1.11-1.28-1.99,0-1.22,.99-2.2,2.2-2.2s2.2,.99,2.2,2.2c0,.89-.53,1.64-1.28,1.99Z"/></svg>');
}
[data-pay="2"] .pay::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%238e8e8e;}</style></defs><path class="cls-1" d="M26.03,30.08H9.97c-1.59,0-2.89-1.3-2.89-2.89v-9.87c0-1.59,1.3-2.89,2.89-2.89H26.03c1.59,0,2.89,1.3,2.89,2.89v9.87c0,1.59-1.3,2.89-2.89,2.89ZM9.97,16.43c-.49,0-.89,.4-.89,.89v9.87c0,.49,.4,.89,.89,.89H26.03c.49,0,.89-.4,.89-.89v-9.87c0-.49-.4-.89-.89-.89H9.97Z"/><path class="cls-1" d="M25.87,15.13h-2.5v-1.33c0-2.97-2.42-5.39-5.39-5.39s-5.39,2.42-5.39,5.39v1.27h-2.5v-1.27c0-4.35,3.54-7.89,7.89-7.89s7.89,3.54,7.89,7.89v1.33Z"/><path class="cls-1" d="M18.99,23.05v2.48h-1.85v-2.48c-.75-.35-1.28-1.11-1.28-1.99,0-1.22,.99-2.2,2.2-2.2s2.2,.99,2.2,2.2c0,.89-.53,1.64-1.28,1.99Z"/></svg>');
}
[data-pay="3"] .pay::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%233471d6;}</style></defs><path class="cls-1" d="M26.03,30.08H9.97c-1.59,0-2.89-1.3-2.89-2.89v-9.87c0-1.59,1.3-2.89,2.89-2.89H26.03c1.59,0,2.89,1.3,2.89,2.89v9.87c0,1.59-1.3,2.89-2.89,2.89ZM9.97,16.43c-.49,0-.89,.4-.89,.89v9.87c0,.49,.4,.89,.89,.89H26.03c.49,0,.89-.4,.89-.89v-9.87c0-.49-.4-.89-.89-.89H9.97Z"/><path class="cls-1" d="M25.87,15.13h-2.5v-1.33c0-2.97-2.42-5.39-5.39-5.39s-5.39,2.42-5.39,5.39v1.27h-2.5v-1.27c0-4.35,3.54-7.89,7.89-7.89s7.89,3.54,7.89,7.89v1.33Z"/><path class="cls-1" d="M18.99,23.05v2.48h-1.85v-2.48c-.75-.35-1.28-1.11-1.28-1.99,0-1.22,.99-2.2,2.2-2.2s2.2,.99,2.2,2.2c0,.89-.53,1.64-1.28,1.99Z"/></svg>');
}

[data-photo="0"] .pho {
  display: none;
}
[data-photo="1"] .pho {
  display: inline-block;
}
[data-photo="0"] .pho::before {
  display: none;
  margin-right: 0;
}
[data-photo="1"] .pho::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%233471d5;}</style></defs><path class="cls-1" d="M27.74,10.65h-4.1l-.36-.91c-.34-1.12-1.31-2.43-2.92-2.43h-4.75c-1.62,0-2.58,1.31-2.89,2.35l-.39,.99h-4.06c-1.75,0-3.18,1.43-3.18,3.18v11.67c0,1.75,1.43,3.18,3.18,3.18H27.74c1.75,0,3.18-1.43,3.18-3.18V13.83c0-1.75-1.43-3.18-3.18-3.18Zm1.18,14.85c0,.65-.53,1.18-1.18,1.18H8.26c-.65,0-1.18-.53-1.18-1.18V13.83c0-.65,.53-1.18,1.18-1.18h5.42l.92-2.33s.31-1.01,1-1.01h4.75c.69,0,.99,.97,1.03,1.09l.89,2.25h5.46c.65,0,1.18,.53,1.18,1.18v11.67Z"/><path class="cls-1" d="M17.99,13.94c-3.03,0-5.49,2.46-5.49,5.49s2.46,5.49,5.49,5.49,5.49-2.46,5.49-5.49-2.46-5.49-5.49-5.49Zm0,8.98c-1.92,0-3.49-1.57-3.49-3.49s1.57-3.49,3.49-3.49,3.49,1.57,3.49,3.49-1.57,3.49-3.49,3.49Z"/></svg>');
}

[data-mov="0"] .mov {
  display: none;
}
[data-mov="1"] .mov {
  display: inline-block;
}
[data-mov="1"] .mov {
  display: inline-block;
  position: relative;
  bottom: 0.24rem;
}
[data-mov="0"] .mov::before {
  display: none;
  margin-right: 0;
}
[data-mov="1"] .mov::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%233471d5;}</style></defs><path class="cls-1" d="M27.82,6.62H8.18c-1.65,0-3,1.35-3,3V26.38c0,1.65,1.35,3,3,3H27.82c1.65,0,3-1.35,3-3V9.62c0-1.65-1.35-3-3-3Zm1,3v1.11h-2.54v-2.11h1.54c.55,0,1,.45,1,1ZM9.61,14.1h-2.43v-2.38h2.43v2.38Zm0,1v2.38h-2.43v-2.38h2.43Zm0,3.38v2.38h-2.43v-2.38h2.43Zm0,3.38v2.38h-2.43v-2.38h2.43Zm1-13.25h14.66V27.38H10.61V8.62Zm15.66,13.25h2.54v2.38h-2.54v-2.38Zm0-1v-2.38h2.54v2.38h-2.54Zm0-3.38v-2.38h2.54v2.38h-2.54Zm0-3.38v-2.38h2.54v2.38h-2.54ZM8.18,8.62h1.43v2.11h-2.43v-1.11c0-.55,.45-1,1-1Zm-1,17.77v-1.14h2.43v2.14h-1.43c-.55,0-1-.45-1-1Zm20.63,1h-1.54v-2.14h2.54v1.14c0,.55-.45,1-1,1Z"/><path class="cls-1" d="M21.44,17.3l-5.51-3.18c-.7-.41-1.58,.1-1.58,.91v6.36c0,.81,.88,1.32,1.58,.91l5.51-3.18c.7-.41,.7-1.42,0-1.82Z"/></svg>');
}

/* LIST ARTICLE
____________________________________________________ */
.row1 > * { flex: 0 0 100%; }
.row2 > * { flex: 0 0 100%; }
.row3 > * { flex: 0 0 100%; }
.row4 > * { flex: 0 0 calc(100% / 2 - 2%); }
.row5 > * { flex: 0 0 calc(100% / 2 - 2%); }

.row2.type02 > *,
.row3.type02 > *,
.row4.type02 > * { flex: 0 0 calc(100% / 2 - 2%); }

.row2.type03 > *,
.row3.type03 > *,
.row4.type03 > * { flex: 0 0 calc(100% / 2 - 2%); }

@media ( min-width: 641px ) {
  .row1 > * { flex: 0 0 100%; }
  .row2 > * { flex: 0 0 calc(100% / 2 - 2%); }
  .row3 > * { flex: 0 0 calc(100% / 2 - 2%); }
  .row4 > * { flex: 0 0 calc(100% / 3 - 1.5%); }
  .row5 > * { flex: 0 0 calc(100% / 3 - 1.5%); }


  .row3.type02 > * { flex: 0 0 calc(100% / 3 - 1.5%); }
  .row4.type02 > * { flex: 0 0 calc(100% / 3 - 1.5%); }

  .row2.type03 > *,
  .row3.type03 > *,
  .row4.type03 > * { flex: 0 0 calc(100% / 3 - 1.5%); }

  .row2.type02::after,
  .row3.type02::after,
  .row2.type03::after,
  .row3.type03::after,
  .row4::after,
  .row5::after {
    content: '';
    flex: 0 0 calc(100% / 3 - 1.5%);
  }
}
@media ( min-width: 834px ) {
  .row1 > * { flex: 0 0 100%; }
  .row2 > * { flex: 0 0 calc(100% / 2 - 2%); }
  .row3 > * { flex: 0 0 calc(100% / 3 - 1.5%); }
  .row4 > * { flex: 0 0 calc(100% / 3 - 1.5%); }
  .row5 > * { flex: 0 0 calc(100% / 3 - 1.5%); }

  .row2.type02 > * { flex: 0 0 calc(100% / 3 - 1.5%); }
  .row4.type02 > * { flex: 0 0 calc(100% / 4 - 1%); }

  .row2.type02::after,
  .row3::after,
  .row4::after,
  .row5::after {
    content: '';
    flex: 0 0 calc(100% / 3 - 1.5%);
  }
}
@media ( min-width: 1281px ) {
  .row1 > * { flex: 0 0 100%; }
  .row2 > * { flex: 0 0 calc(100% / 2 - 2%); }
  .row3 > * { flex: 0 0 calc(100% / 3 - 1.5%); }
  .row4 > * { flex: 0 0 calc(100% / 4 - 1%); }
  .row5 > * { flex: 0 0 calc(100% / 5 - 1%); }

  .row2.type02 > * { flex: 0 0 calc(100% / 2 - 2%); }

  .row3::after,
  .row5::after {
    content: '';
    flex: 0 0 calc(100% / 3 - 1.5%);
  }
}

.meta-cat:empty { display: none !important; }
.excerpt:empty { display: none !important; }

[class*="row"]:not([class*="arrow"]) {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


/* LIST ARTICLE type01
____________________________________________________ */
.article-list.type01 li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(var(--color00),0.2);
}
.article-list.type01 .thumb {
  order: 2;
  width: 28%;
  height: auto;
  aspect-ratio: 4 / 3;
  background: rgba(var(--color00),0.05);
}

.article-list.type01 .thumb2 {
  order: 2;
  width: 28%;
  height: auto;
  aspect-ratio: 4 / 3;
  background: rgba(var(--color00),0.05);
}

.article-list.type01 .detail {
  order: 1;
  width: 72%;
  padding: 0.25rem 1rem 0 0;
}

.article-list.type01 .detail2 {
  order: 1;
  width: 72%;
  padding: 0.25rem 1rem 0 0;
}

.article-list.type01 .detail .caption {
  display: block;
  font-size: min(4vw, 18px);
  font-weight: 700;
  color: rgba(var(--color00),0.85);
  letter-spacing: 0.05em;
  line-height: 1.4;
  margin-bottom: 0.25em;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.article-list.type01 .detail .pubmeta {
  font-size: min(3.5vw, 13px);
  display: flex;
  align-items: left;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.25rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.article-list.type01 .detail .pubmeta .meta-time:not(:empty) {
  display: inline-block;
  color: rgba(var(--color00),1);
  margin: 0.25rem 0.5rem 0.25rem 0;
}
.article-list.type01 .detail .pubmeta .meta-cat:not(:empty) {
  color: rgba(var(--color05),1);
  margin: 0.25rem 0.5rem 0.25rem 0;
  width: auto;
}
.article-list.type01 .detail .excerpt {
  letter-spacing: 0.1em;
  width: 100%;
  display: block;
  font-size: min(3.5vw,14px);
  color: rgba(var(--color00),1);
  line-height: 1.6;
  text-align: justify;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.article-list.type01 .detail2 .excerpt {
  letter-spacing: 0.1em;
  width: 100%;
  display: block;
  font-size: min(3.5vw,14px);
  color: rgba(var(--color00),1);
  line-height: 1.6;
  text-align: justify;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.article-list.type01 .detail .excerpt2 {
  letter-spacing: 0.1em;
  width: 100%;
  display: block;
  font-size: min(3.5vw,14px);
  color: rgba(var(--color00),1);
  line-height: 1.6 !important;
  text-align: justify;
  word-break: break-word;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}


.article-list.type01.smallthumb > * {
  align-items: center;
}
.article-list.type01.smallthumb .thumb {
  width: min(30%, 90px);
}
.article-list.type01.smallthumb .detail {
  width: calc( 100% - min(30%, 90px));
  padding: 0 1rem 0 0;
}
.article-list.type01.smallthumb .detail .caption {
  margin-bottom: 0;
  padding-top: calc(1em / 1.4);
}
/* .article-list.type01.nothumb1st > *:nth-of-type(1) {
  width: 100%;
}
.article-list.type01.nothumb1st > *:nth-of-type(1) .thumb {
  display: none;
}
.article-list.type01.nothumb1st > *:nth-of-type(1) .detail {
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
}
.article-list.type01.nothumb1st > *:nth-of-type(1) .detail .caption {
  max-height: 3.25em;
} */


/* LIST ARTICLE type02
____________________________________________________ */
.article-list.type02 li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 1rem 0;
}
.article-list.type02 .thumb {
  width: 100%;
  height: auto;
  aspect-ratio: 4 / 3;
}
.article-list.type02 .detail {
  width: 100%;
  padding: .75em 0 0 0;
}
.article-list.type02 .detail .caption {
  display: block;
  font-size: min(3.5vw, 17px);
  font-weight: 700;
  color: rgba(var(--color00),0.85);
  letter-spacing: 0;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.article-list.type02.row3 .detail .caption {
  /* font-size: min(3vw, 17px); */
}
.article-list.type02 .detail .pubmeta {
  font-size: min(3.5vw, 13px);
  display: flex;
  align-items: left;
  flex-wrap: wrap;
  flex-direction: row;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.article-list.type02 .detail .pubmeta .meta-time:not(:empty) {
  display: inline-block;
  margin: 0.25rem 0.5rem 0.25rem 0;
}
.article-list.type02 .detail .pubmeta .meta-caption {
  width: 100%;
  color: rgba(var(--color00),0.85);
  font-weight: 700;
  font-size: min(3vw, 13px);
  margin-bottom: 0.25rem;
}
.article-list.type02 .detail .pubmeta .meta-cat:not(:empty) {
  color: rgba(var(--color05),1);
  margin: 0.25rem 0.5rem 0.25rem 0;
  width: auto;
}
.article-list.type02 .detail .excerpt {
  display: none;
}
.article-list.type02.row1 .detail .caption {
  font-size: min(4.5vw, 24px);
  margin-bottom: .5em;
  -webkit-line-clamp: 3;
}
.article-list.type02.row1 .detail .pubmeta {
  flex-wrap: nowrap;
  margin-bottom: 0.25rem;
}
.article-list.type02.row1 .detail .excerpt {
  display: block;
  color: rgba(var(--color00),1);
  font-size: min(3vw,16px);
  line-height: 1.6;
  letter-spacing: 0.1em;
}


/* LIST ARTICLE type03
____________________________________________________ */
.article-list.type03 li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}
.article-list.type03 .thumb {
  width: 100%;
  height: auto;
  aspect-ratio: 300 / 175;
  background: rgba(var(--color00),0.05);
}
.article-list.type03 .detail {
  width: 100%;
  padding: 1.25rem 1rem 0.5rem 1rem;
  position: relative;
}
.article-list.type03 .detail .caption {
  display: block;
  font-size: min(3.5vw, 16px);
  font-weight: 700;
  color: rgba(var(--color00),0.85);
  letter-spacing: 0;
  line-height: 1.2;
  margin-bottom: 0;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.article-list.type03 .detail .pubmeta .meta-time {
  display: none;
}
.article-list.type03 .detail .pubmeta .meta-cat:not(:empty) {
  position: absolute;
  z-index: 3;
  bottom: calc( 100% - 0.75em );
  left: 0px;
  font-size: min(3vw, 14px);
  background: rgba(var(--color05),1);
  color: rgba(var(--color08),1);
  line-height: 1;
  padding: 0.35rem 1rem;
}
.article-list.type03 .detail .excerpt {
  display: none;
}


/* LIST ARTICLE type04
____________________________________________________ */
.article-list.type04 li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(var(--color00),0.2);
  position: relative;
}
.article-list.type04 .thumb {
  order: 1;
  width: auto;
  height: 3.75rem;
  aspect-ratio: 4 / 3;
  position: absolute;
  bottom: 1.25rem;
  background: rgba(var(--color00),0.05);
}
.article-list.type04 .detail {
  order: 2;
  width: 100%;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.article-list.type04 .detail .caption {
  display: block;
  width: 100%;
  font-size: min(4vw, 20px);
  font-weight: 700;
  color: rgba(var(--color00),0.85);
  letter-spacing: 0.05em;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  text-align: justify;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.article-list.type04 .detail .pubmeta {
  font-size: min(3.5vw, 13px);
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.article-list.type04 .detail .pubmeta .meta-time:not(:empty) {
  display: inline-block;
  margin: 0.25rem 0.5rem 0.25rem 0;
}
.article-list.type04 .detail .pubmeta .meta-cat:not(:empty) {
  color: rgba(var(--color05),1);
  margin: 0.25rem 0.5rem 0.25rem 0;
  width: auto;
}
.article-list.type04 .detail .excerpt {
  width: calc( 100% - (3.75rem * (4 / 3)) );
  display: block;
  font-size: min(3vw,14px);
  color: rgba(var(--color00),1);
  line-height: 1.5;
  text-align: justify;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0 0 0 auto;
  padding-left: 1rem;
}
@media ( min-width: 834px ) {
  .article-list.type04 li {
    padding: 2rem 0;
    position: static;
  }
  .article-list.type04 .thumb {
    width: 20%;
    height: auto;
    position: relative;
    margin: 0;
    bottom: 0;
  }
  .article-list.type04 .detail {
    width: 80%;
    padding: 0 0 0 1rem;
  }
  .article-list.type04 .detail .caption {
    -webkit-line-clamp: 4;
    margin-bottom: 0.5rem;
    min-height: auto;
  }
  .article-list.type04 .detail .excerpt {
    width: 100%;
    margin: 0;
    padding: 0;
    -webkit-line-clamp: 3;
  }
}


/* LIST ARTICLE type05
____________________________________________________ */
.article-list.type05 li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 0;
}
.article-list.type05 .thumb {
  order: 1;
  width: 42%;
  height: auto;
  aspect-ratio: 1 / 1;
  background: rgba(var(--color00),0.05);
}
.article-list.type05 .detail {
  order: 2;
  width: 58%;
  padding: 0.5rem 0 0 1.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.article-list.type05 .detail .caption {
  display: block;
  width: 100%;
  font-size: min(4vw, 24px);
  font-weight: 700;
  color: rgba(var(--color00),0.85);
  letter-spacing: 0.05em;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.article-list.type05 .detail .pubmeta {
  width: 100%;
  color: rgba(var(--color00),0.75);
  font-size: min(3.5vw, 13px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.article-list.type05 .detail .pubmeta .meta-caption {
  width: 100%;
  font-weight: 700;
  font-size: min(4vw, 16px);
  border-bottom: 1px solid rgba(var(--color00),0.2);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.article-list.type05 .detail .pubmeta .meta-time:not(:empty) {
  display: inline-block;
  margin: 0.25rem 0.5rem 0.25rem 0;
}
.article-list.type05 .detail .pubmeta .meta-cat:not(:empty) {
  color: rgba(var(--color05),1);
  margin: 0.25rem 0.5rem 0.25rem 0;
  width: auto;
}
.article-list.type05 .detail .excerpt {
  width: 100%;
  display: block;
  font-size: min(3vw,14px);
  line-height: 1.5;
}


/* LIST ARTICLE type06
____________________________________________________ */
.article-list.type06 li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(var(--color00),0.2);
  margin-bottom: 1rem;
}
.article-list.type06 .thumb {
  display: none;
}
.article-list.type06 .detail {
  width: 100%;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.article-list.type06 .detail .caption {
  display: block;
  width: calc( 100% - calc(min(3.5vw, 13px) * 12));
  /* font-size: min(4vw, 20px); */
  font-weight: 700;
  color: rgba(var(--color00),0.85);
  letter-spacing: 0.05em;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.article-list.type06 .detail .pubmeta {
  color: rgba(var(--color00),0.75);
  margin-bottom: 0.25rem;
  font-size: min(3.5vw, 13px);
  font-weight: 700;
  text-align: right;
  display: flex;
  align-items: center;
}
.article-list.type06 .detail .pubmeta .ic {
  display: none;
}
.article-list.type06 .detail .pubmeta::before,
.article-list.type06 .detail .pubmeta::after {
  display: none;
}
.article-list.type06 .detail .pubmeta .meta-time:not(:empty) {
  order: 2;
  margin: 0.25rem 0.5rem 0.25rem 0;
}
.article-list.type06 .detail .pubmeta .meta-cat:not(:empty) {
  display: none;
}
.article-list.type06 .detail .excerpt {
  display: none;
}


/* LIST ARTICLE type07
____________________________________________________ */
.article-list.type07 li {
  display: flex;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(var(--color00),0.2);
}
.article-list.type07 li::before {
  content: '';
  display: block;
  width: 1.5em;
  height: 1.5em;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m18.75 37.2-3.35-3.35 9.9-9.9-9.9-9.9 3.35-3.35L32 23.95Z" fill="%23333"/></svg>');
}
.article-list.type07 .thumb {
  display: none;
}
.article-list.type07 .detail {
  width: calc( 100% - 1.5em );
  padding: 0;
  display: flex;
  flex-direction: column;
}
.article-list.type07 .detail .caption {
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 1rem;
  font-size: min(4vw, 16px);
  font-weight: 700;
  color: rgba(var(--color00),0.85);
  line-height: 1.4;
  word-break: break-word;
  display: flex;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.article-list.type07 .detail .pubmeta {
  color: rgba(var(--color00),0.75);
  font-size: min(3.5vw, 13px);
  display: flex;
  align-items: center;
}
.article-list.type07 .detail .pubmeta .meta-time:not(:empty) {
  margin: 0.25rem 0.5rem 0.25rem 0;
}
.article-list.type07 .detail .pubmeta .meta-cat {
  display: none;
}
.article-list.type07 .detail .excerpt {
  display: none;
}


/* LIST ARTICLE type08
____________________________________________________ */
.article-list.type08 li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  margin: 1rem 0;
}
.article-list.type08 .thumb {
  width: 36%;
  height: auto;
  aspect-ratio: 4 / 3;
  position: relative;
  overflow: hidden;
  background: rgba(var(--color00),0.05);
}
.article-list.type08 .thumb img {
  position: absolute;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.article-list.type08 .detail {
  width: 64%;
  padding: 0em 0 0 5.5%;
}
.article-list.type08 .detail .caption {
  display: block;
  font-size: min(3.5vw, 16px);
  font-weight: 700;
  color: rgba(var(--color00),0.85);
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin-bottom: .35em;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.article-list.type08 .detail .pubmeta {
  font-size: min(3.5vw, 13px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.article-list.type08 .detail .pubmeta .meta-time:not(:empty) {
  display: inline-block;
  margin: 0.25rem 0.5rem 0.25rem 0;
}
.article-list.type08 .detail .pubmeta .meta-caption {
  width: 100%;
  color: rgba(var(--color00),0.85);
  font-weight: 700;
  font-size: min(3vw, 13px);
  margin-bottom: 0.5rem;
}
.article-list.type08 .detail .pubmeta .meta-cat {
  color: rgba(var(--color05),1);
  margin: 0.25rem 0.5rem 0.25rem 0;
  width: auto;
}
.article-list.type08 .detail .excerpt {
  display: block;
  color: rgba(var(--color00),1);
  font-size: min(3vw,12px);
  line-height: 1.4;
  letter-spacing: 0;
}


/* LIST ARTICLE type09
____________________________________________________ */
.article-list.type09 li {
  display: flex;
  padding: 1rem 0 0.25rem 0;
  border-bottom: 1px solid rgba(var(--color00),0.2);
}
.article-list.type09 .thumb {
  display: none;
}
.article-list.type09 .detail {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.article-list.type09 .detail .caption {
  width: -moz-fit-content;
  width: fit-content;
  font-size: min(4vw, 16px);
  font-weight: 700;
  color: rgba(var(--color00),0.85);
  line-height: 1.4;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0.5rem;
}
.article-list.type09 .detail .pubmeta {
  font-size: min(3.5vw, 13px);
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.25rem;
}
.article-list.type09 .detail .pubmeta .meta-time:not(:empty) {
  display: inline-block;
  margin: 0.25rem 0.5rem 0.25rem 0;
}
.article-list.type09 .detail .pubmeta .meta-cat {
  color: rgba(var(--color05),1);
  margin: 0.25rem 0.5rem 0.25rem 0;
  width: auto;
}
.article-list.type09 .detail .excerpt {
  letter-spacing: 0.1em;
  width: 100%;
  display: block;
  font-size: min(3vw,14px);
  color: rgba(var(--color00),1);
  line-height: 1.6;
  text-align: justify;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}


/* LIST ARTICLE type10
____________________________________________________ */
.article-list.type10 li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 0;
  border-bottom: 1px solid rgba(var(--color00),0.5);
}
.article-list.type10 .thumb {
  display: none;
}
.article-list.type10 .detail {
  width: 100%;
  padding: 0;
  position: relative;
}
.article-list.type10 .detail .caption {
  display: block;
  font-size: min(3.5vw, 18px);
  font-weight: 700;
  margin-bottom: 1rem;
  color: rgba(var(--color00),1);
  letter-spacing: 0;
  line-height: 1.2;
}
.article-list.type10 .detail .pubmeta {
  display: none;
}
.article-list.type10 .detail .excerpt {
  font-size: min(3.5vw, 16px);
  line-height: 1.6;
  color: rgba(var(--color00),1);
  text-align: justify;
}


/* LIST ARTICLE type01 + recent
____________________________________________________ */
.article-list.type01.feature1st > *:not(:nth-of-type(1)) {
  border-bottom: 1px solid rgba(var(--color00),0.2)!important;
}
.article-list.type01.feature1st > *:nth-of-type(1) {
  position: relative;
  flex-direction: column;
  flex: 0 0 100%;
}
.article-list.type01.feature1st > *:nth-of-type(1) .thumb {
  order: 1;
  width: 100%;
  height: auto;
  margin-bottom: 0.5rem;

  z-index: 1;
  margin-top: calc( min(5vw, 21px) * 3 );
}
.article-list.type01.feature1st > *:nth-of-type(1) .detail {
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
}
.article-list.type01.feature1st > *:nth-of-type(1) .detail .caption {
  font-size: min(5vw, 21px);
  padding: 0.5rem 0;
  max-height: 3.25em;

  position: absolute;
  top: 0;
  z-index: 1;
}

.article-list.type01.feature1st > *:nth-of-type(1) .thumb2 {
  order: 1;
  width: 100%;
  height: auto;
  margin-bottom: 0.5rem;
  z-index: 1;
}
.article-list.type01.feature1st > *:nth-of-type(1) .detail2 {
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  padding: 1.5rem 0;
}
.article-list.type01.feature1st > *:nth-of-type(1) .detail2 .caption {
  font-size: min(5vw, 21px);
  padding: 0.5rem 0;
  max-height: 3.25em;

  position: absolute;
  top: 0;
  z-index: 1;
}

@media ( min-width: 641px ) {
  .article-list.type01.feature1st > *:nth-of-type(1) {
    border-bottom: none;
    flex-direction: row;
    align-items: flex-start;
  }
  .article-list.type01.feature1st > *:nth-of-type(1) .thumb {
    order: 1;
    width: 36%;

    margin-top: 0;
  }
  .article-list.type01.feature1st > *:nth-of-type(1) .detail {
    order: 2;
    width: calc( 100% - 36% );
    padding: 0.25rem 0 0 2rem;
    position: static;
    background: transparent;
  }
  .article-list.type01.feature1st > *:nth-of-type(1) .detail .caption {
    max-height: auto;
    letter-spacing: 0.05em;
    line-height: 1.5;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    color: rgba(var(--color00),0.85);
    padding: 0;

    position: static;
  }
  .article-list.type01.feature1st > *:nth-of-type(1) .detail .pubmeta {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
  }

  .article-list.type01.feature1st > *:nth-of-type(1) .thumb2 {
    order: 1;
    width: 36%;

    margin-top: 0;
  }
  .article-list.type01.feature1st > *:nth-of-type(1) .detail2 {
    order: 2;
    width: calc( 100% - 36% );
    padding: 0.25rem 0 0 2rem;
    position: static;
    background: transparent;
  }
  .article-list.type01.feature1st > *:nth-of-type(1) .detail2 .caption {
    max-height: auto;
    letter-spacing: 0.05em;
    line-height: 1.5;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    color: rgba(var(--color00),0.85);
    padding: 0;

    position: static;
  }
  .article-list.type01.feature1st > *:nth-of-type(1) .detail2 .pubmeta {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
  }

}


/* LIST ARTICLE type01 + Today's Photo
____________________________________________________ */
.article-list.hasToday li {
  width: 100%;
  min-height: calc( 110px * 2 );
  height: calc( var(--todays-item-H) * 2 );
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: none !important;
  padding: 0.5rem 0 0 0;
  overflow: hidden;
}
.article-list.hasToday li::before {
  content: '';
  display: block;
  position: absolute;
  top: 0.5rem;
  width: 100%;
  height: calc(100% - (0.5rem));
  background: rgba(var(--color07),1);
  z-index: 0;
}
.article-list.hasToday .detail .caption {
  text-align: left;
  /* padding: 0 1em; */
}
.article-list.hasToday .detail .pubmeta {
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
}
.article-list.hasToday .detail .pubmeta .meta-time:not(:empty) {
  display: block;
  text-align: left;
  width: 100%;
  margin: 0.25rem 0;
}
.article-list.hasToday .thumb {
  /* width: calc( (var(--todays-item-H) * 2) * (4 / 3) );
  height: calc( var(--todays-item-H) * 2 - 0.5rem ); */

  width: calc( min(60%, (var(--todays-item-H) * 2) * (4 / 3)) );
  height: auto;
}
.article-list.hasToday .detail {
  /* width: calc( 100% - (var(--todays-item-H) * 2) * (4 / 3)); */
  width: calc( 100% - min(60%, (var(--todays-item-H) * 2) * (4 / 3)) );
  padding: 0;
  z-index: 1;
  margin-top: 0.5rem;
}
@media ( min-width: 834px ) {
  .article-list.hasToday .thumb {
    width: calc( min(72%, (var(--todays-item-H) * 2) * (4 / 3)) );
    height: auto;
  }
  .article-list.hasToday .detail {
    width: calc( 100% - min(72%, (var(--todays-item-H) * 2) * (4 / 3)) );
  }
}



.article-list.hasToday .detail .excerpt {
  display: none;
}
/* @media ( min-width: 641px ) and ( max-width: 1280px ) {
  .article-list.hasToday .thumb {
    width: calc( min(60%, (var(--todays-item-H) * 2) * (4 / 3)) );
    height: auto;
  }
  .article-list.hasToday .detail {
    width: calc( 100% - min(60%, (var(--todays-item-H) * 2) * (4 / 3)) );
    padding: 0.5rem 0 0 0;
    z-index: 1;
  }
} */


/* LIST ARTICLE type01 + newspaper
____________________________________________________ */
.article-list.type01.newspaper li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(var(--color00),0.2);
}
.article-list.type01.newspaper .thumb {
  order: 2;
  width: 30%;
  height: auto;
  aspect-ratio: 200 / 266;
}
.article-list.type01.newspaper .detail {
  order: 1;
  width: 70%;
  padding: 0.5rem 2em 0 0;
}
.article-list.type01.newspaper .detail .caption {
  display: block;
  font-size: min(4vw, 24px);
  font-weight: 700;
  color: rgba(var(--color00),0.85);
  letter-spacing: 0.05em;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.article-list.type01.newspaper .detail .pubmeta {
  font-size: min(3.5vw, 13px);
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}
.article-list.type01.newspaper .detail .pubmeta .meta-time:not(:empty) {
  display: inline-block;
}
.article-list.type01.newspaper .detail .excerpt {
  letter-spacing: 0.1em;
  width: 100%;
  display: block;
  font-size: min(3vw,14px);
  line-height: 1.6;
  text-align: justify;
}
